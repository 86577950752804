<template>
	<router-view/>

	<Footer />
</template>

<script>
import 'tailwindcss/tailwind.css'
import Footer from '@/components/core/Footer'
import { useRoute } from 'vue-router'
import { watch } from 'vue'

export default {
	components: {
		Footer
	},
	setup() {
		const route = useRoute();

		watch(route, (current, old) => {
			window.scrollTo(0, 0);
		});
	},
}
</script>
