import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from "vue-gtag-next";

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('@/views/Home.vue')
	},
	{
		path: '/acerca-de',
		name: 'Acerca-de',
		component: () => import('@/views/Acercade.vue')
	},
	{
		path: '/escuelas',
		name: 'Escuelas',
		component: () => import('@/views/Escuelas.vue')
	},
	{
		path: '/padres-de-familia',
		name: 'Padres-de-Familia',
		component: () => import('@/views/Padres.vue')
	},
	{
		path: '/asesoria-psicologica',
		name: 'Asesoria-Psicologica',
		component: () => import('@/views/Asesoria.vue')
	},
	{
		path: '/material-didactico',
		name: 'Apps',
		component: () => import('@/views/Material.vue')
	},
	{
		path: '/material-didactico/yo-me-quiero-yo-me-cuido',
		name: 'M01-YoMeQuiero',
		component: () => import('@/views/material/M01.vue')
	},
	{
		path: '/material-didactico/como-soy',
		name: 'M02-ComoSoy',
		component: () => import('@/views/material/M02.vue')
	},
	{
		path: '/material-didactico/una-nueva-vida',
		name: 'M03-UnaNuevaVida',
		component: () => import('@/views/material/M03.vue')
	},
	{
		path: '/blog',
		name: 'Blog',
		component: () => import('@/views/Blog.vue')
	},
	{
		path: '/blog/:id',
		name: 'BlogEntry',
		component: () => import('@/views/BlogEntry.vue')
	},
	{
		path: '/aviso-de-privacidad',
		name: 'Aviso-de-Privacidad',
		component: () => import('@/views/Aviso.vue')
	},
	{
		path: '/terminos-y-condiciones',
		name: 'Terminos-y-Condiciones',
		component: () => import('@/views/Terminos.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

trackRouter(router);

export default router
